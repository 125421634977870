.Icon-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Icon-section a {
  margin: 30px 20px;
}

@media only screen and (max-width: 840px) {
  .Icon-section a {
    margin-bottom: 10px;
  }
}
