.Book-page {
  top: 0;
  z-index: 2;
  background-color: rgb(27, 23, 24);
  transform: none;
  position: absolute;
}

.Book-page-Text {
  background-color: white;
  padding: 5vh 25vw;
}

.Book-page-Text h1 {
  font-size: calc(10px + 3vmin);
  font-weight: 600;
}

.Book-page-Text p {
  font-size: calc(10px + 1vmin);
  font-weight: 300;
}

.Book-page-convert {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 20px auto;
  bottom: 0;
  width: 100vw;
}

.Book-page-convert a {
  min-width: 125px;
  color: white !important;
  background-color: rgb(235, 82, 76);
  padding: 2px 6vw;
  text-transform: uppercase;
  font-size: calc(10px + 1.2vmin);
  font-weight: 300;
  letter-spacing: 0.8vmin;
  transition: 0.5s ease;
}

.Book-page-convert p {
  letter-spacing: 0.3vmin;
}

.Book-page-convert a:hover {
  background-color: rgba(208, 52, 46, 1);
  border-radius: 10vw;
}

.Book-page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 20px;
  width: 100px;
  height: 100px;
  z-index: 5;
  background-color: white;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
  transition: 0.5s ease;
  border-radius: 50px;
  top: 0;
}

.Book-page-link a {
  color: rgb(235, 82, 76);
}

.Book-page-link p {
  letter-spacing: 0;
  line-height: 0;
  padding: 0;
  color: rgb(235, 82, 76);
}

.Book-page-link:hover {
  background-color: rgba(208, 52, 46, 1);
}

.Book-page-link:hover p {
  color: white;
}

.Book-page .Section {
  padding: 5vmin;
}
.Book-page .Section-left img {
  width: 30vw;
  padding-left: 5vmin;
}

.Book-page .Section-right {
  padding: 5vmin 0;
  color: white;
}

.Book-page .Section-right p {
  padding: 0;
  font-size: calc(10px + 1vmin);
  font-weight: 300;
}

.Book-page .Convert-button {
}

@media only screen and (max-width: 1660px) {
  .Book-page-Text {
    padding: 5vh 15vw;
  }
}
@media only screen and (max-width: 1480px) {
  .Book-page-Text {
    padding: 5vh 10vw;
  }
}
@media only screen and (max-width: 1280px) {
  .Book-page-Text {
    padding: 5vh 5vw;
  }
  .Book-page .Section-left {
    width: 50%;
  }
  .Book-page .Section-right p {
    text-align: left;
  }
  .Book-page .Section-left img {
    width: 50vw;
    padding-left: 0vmin;
  }
}
@media only screen and (max-width: 840px) {
  .Book-page-link {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
  .Book-page-Text {
    padding: 5vh 5vw;
  }
}
