.content {
  text-align: center;
  animation-delay: 5s;
  animation: Page-load 1 1s ease-in;
}

@keyframes Page-load {
  from {
    transform: translateY(5px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
