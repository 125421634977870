.Display-portfolio-list {
  margin: 2% 5%;
  animation: Page-load 1 0.5s ease-in;
}

.Display-portfolio-list ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
  animation: Page-load 1 0.5s ease-in;
  padding: 0;
}

.Display-portfolio-list ul li {
  list-style-type: none;
}

.Display-portfolio-page .Portfolio-button {
  display: none;
}

@media only screen and (max-width: 1660px) {
  .Display-portfolio-list ul {
    margin: 2% 2vw;
  }
}

@media only screen and (max-width: 1480px) {
  .Display-portfolio-list ul {
    margin: 2% 1vw;
  }
}

@media only screen and (max-width: 1280px) {
  .Display-portfolio-list ul {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 2%;
  }
}

@media only screen and (max-width: 840px) {
  .Display-portfolio-list ul {
    grid-template-columns: repeat(1, 1fr);
  }

  .Display-portfolio-page .Portfolio-button {
    display: flex;
    justify-content: center;
    max-width: 100%;
    max-height: 490px;
    margin: auto;
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    box-shadow: none;
    background-color: white;
  }

  .Display-portfolio-page .Portfolio-button:hover {
    box-shadow: none;
    transform: translateY(0px);
    cursor: default;
  }
}
