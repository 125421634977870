.Header {
  text-align: center;
}

.Header nav {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(46, 50, 53);
}

.Header nav ul {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5vw;
  margin: 0 10%;
  padding: 0;
}

.Header nav li {
  text-decoration: none;
  list-style-type: none;
}

.Header nav li a {
  color: rgb(235, 82, 76);
  font-weight: 500;
  transition: 0.5s ease;
}

.Header nav li a:hover {
  transition: 0.5s ease;
  border-bottom: rgb(235, 82, 76) 1px solid;
}

.Header-nav {
  font-size: calc(10px + 0.4vmin);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 90px;
  height: 30px;
}

.Header-avatar {
  margin: 0 40vmin;
}

@media only screen and (max-width: 1480px) {
  .Header-avatar {
    margin: 0 20vmin;
  }
}

@media only screen and (max-width: 1280px) {
  .Header-avatar {
    margin: 0 15vmin;
  }
}

@media only screen and (max-width: 840px) {
  .Header-avatar {
    margin: 0 10vmin;
  }

  .Header nav ul {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10vw;
    margin: 1vmin 5% 10px;
    padding: 0;
  }

  .Header-nav {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .Header nav ul {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1vw;
    margin: 0 2vmin 10px;
    padding: 0;
  }
  .Header-avatar {
    margin: 0 1vmin;
  }
}
