.Greetings p {
  font-weight: 300;
  font-size: calc(10px + 1.5vmin);
  margin: 0;
}

.Greetings h1 {
  font-size: calc(10px + 5.5vmin);
  letter-spacing: -0.4vmin;
  color: rgb(235, 82, 76);
  margin: 0;
}

.Greetings h3 {
  font-size: calc(10px + 1.5vmin);
  letter-spacing: -0.1vmin;
  margin: 0;
}

@media only screen and (max-width: 840px) {
  .Greetings p {
    font-weight: 300;
    font-size: calc(10px + 1vmin);
    margin: 0;
  }
  .Greetings h1 {
    font-size: calc(10px + 3.5vmin);
    letter-spacing: -0.3vmin;
    color: rgb(46, 50, 53);
    margin: 0;
  }

  .Greetings h3 {
    font-size: calc(10px + 1vmin);
    letter-spacing: -0.1vmin;
    margin: 0;
  }
}
