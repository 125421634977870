.Avatar-head {
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  height: 200px;
}

.Avatar-head:hover .Avatar-logo {
  opacity: 1;
}

.Avatar-logo {
  animation: Avatar-logo-spin infinite 5s linear;
  height: 130px;
  pointer-events: none;
  position: absolute;
  opacity: 0;
  transition: 1s ease;
}

.Avatar {
  width: 80px;
  position: absolute;
  z-index: 1;
}

@keyframes Avatar-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 840px) {
  .Avatar {
    width: 10vw;
    min-width: 60px;
  }
  .Avatar-head {
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    height: 80px;
  }
  .Avatar-logo {
    height: 100px;
  }
}
