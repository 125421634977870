.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1em 10vmin;
  align-items: center;
}

.Footer-rights {
  opacity: 0.7;
  font-size: calc(10px + 0.6vmin);
}

@media only screen and (max-width: 840px) {
  .Footer {
    margin: 1em 5vmin;
  }
}
