body {
  margin: 0;
  padding: 0;
  color: rgb(46, 50, 53);
  font-family: aktiv-grotesk, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(to right, #ffffff 49%, #efefef 100%);
  background-repeat: no-repeat;
  min-height: 100vh;
}

a {
  color: rgb(235, 82, 76);
  text-decoration: none;
}
.Home-page {
  animation: Page-load 1 0.5s ease-in;
}

.Home-page-link {
  color: rgb(235, 82, 76);
}

.Home-page-portfolio {
  border-radius: calc(5px + 5vmin);
}

.About-page {
  animation: Page-load 1 0.5s ease-in;
}

.Section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 15vw 5vmin;
}
.Section-left img {
  width: 35vw;
}
.Section-right {
  text-align: left;
}
.Section-right p {
  padding: 0 5vmin;
  line-height: 2.2;
  letter-spacing: 0.1vmin;
}

.Book-page {
  animation: Page-load 1 0.2s ease-in;
}
.Contact-page {
  animation: Page-load 1 0.5s ease-in;
}

@keyframes Page-load {
  from {
    transform: translateY(5px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@media only screen and (max-width: 1660px) {
  .Section {
    margin: 0 10vw 5vmin;
  }
}

@media only screen and (max-width: 1480px) {
}

@media only screen and (max-width: 1280px) {
  .Section {
    flex-direction: column-reverse;
  }
  .Section:first-of-type {
    flex-direction: column;
  }
  .Section-left img {
    width: 100%;
  }
}

@media only screen and (max-width: 840px) {
}

.active {
  color: rgb(46, 50, 53) !important;
  border-bottom: rgb(46, 50, 53) 1px solid;
}
