.Image-button {
  max-width: 540px;
  max-height: 490px;
  margin: auto;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  background: #efefef;
  transition: 0.5s ease;
  box-shadow: 0 8px 29px 0 rgba(0, 0, 0, 0.2);
}

.Image-button img {
  width: 100%;
}

.Image-button-text {
  padding: 15px 20px 15px;
  text-align: left;
  font-size: 1em;
}

.Image-button-text p {
  margin: 5px 0;
}

.Image-button-title {
  font-weight: 200;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.Image-button-description {
  font-weight: 600;
}

.Image-button-overlay {
  max-width: 540px;
  max-height: 490px;
  opacity: 0;
  position: absolute;
  transition: 0.5s ease;
  background-color: #fdfdfd;
  top: 0;
  bottom: 0;
}

.Image-button-image {
  max-width: 540px;
  max-height: 490px;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  background-color: white;
  top: 0;
  font-size: 0;
}

.Image-button-overlay img {
  width: 100%;
}

.Image-button:hover {
  box-shadow: 0 18px 39px 0 rgba(0, 0, 0, 0.4);
  transform: translateY(-10px);
  cursor: pointer;
}

.Image-button:hover .Image-button-image {
  opacity: 0;
}

.Image-button:hover .Image-button-overlay {
  opacity: 1;
}

@media only screen and (max-width: 1140px) {
  .Image-button {
    max-width: 380px;
    max-height: 345px;
  }
  .Image-button-text {
    padding: 10px 20px 15px;
  }
  .Image-button-overlay {
    max-width: 380px;
    max-height: 345px;
  }
}

@media only screen and (max-width: 860px) {
  .Image-button {
    max-width: 540px;
    max-height: 490px;
  }

  .Image-button-overlay {
    max-width: 540px;
    max-height: 490px;
  }

  .Image-button-text p {
    font-size: 3vmin;
  }

  .Image-button-text {
    margin-top: 3vmin;
  }
}
