.Portfolio-button {
  max-width: 540px;
  max-height: 490px;
  margin: auto;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  background: #efefef;
  transition: 0.5s ease;
  box-shadow: 0 8px 29px 0 rgba(0, 0, 0, 0.2);
}

.Portfolio-button img {
  width: 100%;
}

.Portfolio-button-text {
  padding: 15px 20px 15px;
  text-align: left;
  font-size: 1em;
  color: rgb(46, 50, 53);
}

.Portfolio-button-text p {
  margin: 0;
  padding: 0;
}

.Portfolio-button-title {
  font-weight: 300;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  color: rgb(46, 50, 53);
}

.Portfolio-button-description {
  font-weight: 600;
}

.Portfolio-button-overlay {
  max-width: 540px;
  max-height: 490px;
  opacity: 0;
  position: absolute;
  transition: 0.5s ease;
  background-color: #fdfdfd;
  top: 0;
  bottom: 0;
}

.Portfolio-button-image {
  max-width: 540px;
  max-height: 490px;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  background-color: white;
  top: 0;
  font-size: 0;
}

.Portfolio-button-overlay img {
  width: 100%;
}

.Portfolio-button:hover {
  box-shadow: 0 18px 39px 0 rgba(0, 0, 0, 0.4);
  transform: translateY(-10px);
  cursor: pointer;
}

.Portfolio-button:hover .Portfolio-button-image {
  opacity: 0;
}

.Portfolio-button:hover .Portfolio-button-overlay {
  opacity: 1;
}

@media only screen and (max-width: 1140px) {
  .Portfolio-button {
    max-width: 400px;
    max-height: 370px;
  }
  .Portfolio-button-title {
    padding: 5px 20px 15px;
  }
  .Portfolio-button-text {
    padding: 5px 20px 15px;
  }
  .Portfolio-button-overlay {
    max-width: 400px;
    max-height: 370px;
  }
}

@media only screen and (max-width: 860px) {
  .Portfolio-button {
    max-width: 540px;
    max-height: 490px;
  }

  .Portfolio-button-overlay {
    max-width: 540px;
    max-height: 490px;
    opacity: 1;
  }

  .Portfolio-button-text p {
    font-size: 3vmin;
  }

  .Portfolio-button-text {
    margin-top: 3vmin;
  }
}
