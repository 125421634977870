.button {
  min-width: 125px;
  color: white !important;
  background-color: rgb(235, 82, 76);
  padding: 2px 6vw;
  text-transform: uppercase;
  font-size: calc(10px + 1.2vmin);
  font-weight: 300;
  letter-spacing: 0.8vmin;
  transition: 0.5s ease;
}

.button p {
  letter-spacing: 0.3vmin;
}

.button:hover {
  background-color: rgba(208, 52, 46, 1);
  border-radius: 10vw;
}
